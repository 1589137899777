import config from '../../../config.json';
import { reponseStatus, responseErrorStatus, storageKeys } from '../../../constants/Enum.jsx';
import {
  changePassword,
  confirmPhoneNumber,
  createEmailToken,
  forgotPassword,
  getAll,
  getCurrencyByIPAddress,
  getCurrentUserInfos,
  phoneConfirmation,
  resetPassword,
  selfUpdate,
  setDefaultPassword,
  update,
  updatePreference,
  verifyEmail,
} from '../../../services/apiServices/userServices.js';
import ActionTypes from './actionTypes.js';
import Actions from './index.js';

const updateAction = (updateData, id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await update(updateData, id)
      .then(async (res) => {
        if (res?.data?.isSuccess) {
          await dispatch(selfUpdateReducer(false));
        }
        await dispatch(handleErrorReducer(res?.data?.isSuccess));
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(updateReducer());
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        }
      });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.user.Update };
};
const selfUpdateAction = (updateData, requestType, fromCalendar = false) => {
  return async (dispatch) => {
    dispatch(selfUpdateReducer(false));
    dispatch(getByIdReducer([]));
    await selfUpdate(updateData, requestType).then(async (res) => {
      if (fromCalendar) {
        sessionStorage.removeItem(storageKeys.landingRule);
        dispatch(selfUpdateReducer(true));
        dispatch(getByIdReducer([]));
      }
      if (!fromCalendar) {
        dispatch(selfUpdateReducer(''));
      }
      if (res?.data?.isSuccess) {
        let response = res?.data?.data;
        await dispatch(getByIdReducer(response));
      }
    });
  };
};
const selfUpdateReducer = (data) => {
  return { type: ActionTypes.user.SelfUpdate, payload: data };
};
const getAllAction = () => {
  return async (dispatch) => {
    await getAll().then(async (res) => {
      let response = res.data.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return { type: ActionTypes.user.GetAll, payload: { data } };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await dispatch(handleErrorReducer(''));
    await dispatch(handleValidationReducer(''));
    await getCurrentUserInfos(id).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getByIdReducer(response));
      await dispatch(Actions.preferenceActions.setCurrencyAction(response?.currency, true));
      dispatch(selfUpdateReducer(''));
    });
  };
};
const getByIdReducer = (data) => {
  return { type: ActionTypes.user.GetById, payload: { data } };
};

const forgotPasswordAction = (updateData) => {
  return async (dispatch, getState) => {
    const { forgotPasswordAction } = getState().user.loading;
    if (forgotPasswordAction) return;
    dispatch(handleRequestReducer({ forgotPasswordAction: true }));
    dispatch(cleanState(true, true));
    try {
      const res = await forgotPassword(updateData);
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleErrorReducer(res?.data?.isSuccess));
    } catch (error) {
      await handleResponseError(dispatch, error);
    } finally {
      dispatch(handleRequestReducer({ forgotPasswordAction: false }));
    }
  };
};

const resetPasswordAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await resetPassword(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(true));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(false));
      }
    });
  };
};
const createEmailTokenAction = (data) => {
  return async () => {
    await createEmailToken(data);
  };
};
const verifyEmailTokenAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleErrorReducer(''));
    await verifyEmail(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleErrorReducer(true));
        await dispatch(handleValidationReducer(res?.data?.message));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(false));
      }
    });
  };
};
const changePasswordAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await changePassword(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleErrorReducer(true));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(false));
      }
    });
  };
};
const phoneConfirmationAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleErrorReducer(''));
    await phoneConfirmation(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleErrorReducer(true));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(false));
      }
    });
  };
};
const confirmPhoneNumberAction = (updateData, id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await confirmPhoneNumber(updateData, id)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        }
      });
  };
};
const updatePreferenceAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleErrorReducer(''));
    await updatePreference(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleErrorReducer(true));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(false));
      }
    });
  };
};

const getCurrenyByIPAddressAction = () => {
  return async (dispatch) => {
    await getCurrencyByIPAddress().then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(Actions.preferenceActions.setCurrencyAction(res?.data?.data, true));
      }
    });
  };
};

const setDefaultPasswordAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await setDefaultPassword(id)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleErrorReducer(reponseStatus.fail));
        }
      });
  };
};

const handleErrorReducer = (data) => {
  return {
    type: ActionTypes.user.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.user.HandleValidation,
    payload: data,
  };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.user.HandleRequest,
    payload: data,
  };
};
const handleResponseError = async (dispatch, error) => {
  if (error?.response?.status === 400) {
    await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  } else {
    await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
    await dispatch(handleErrorReducer(reponseStatus.fail));
  }
};

const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleErrorReducer(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleErrorReducer(''));
      await dispatch(updateReducer());
    }
  };
};

const userActions = {
  updateAction,
  getByIdAction,
  getAllAction,
  phoneConfirmationAction,
  confirmPhoneNumberAction,
  forgotPasswordAction,
  createEmailTokenAction,
  verifyEmailTokenAction,
  selfUpdateAction,
  changePasswordAction,
  resetPasswordAction,
  updatePreferenceAction,
  getCurrenyByIPAddressAction,
  setDefaultPasswordAction,
  cleanState,
};
export default userActions;
