import { create } from '../../../services/apiServices/userOperationClaimServices.js';
import ActionTypes from './actionTypes.js';

const createAction = (createData) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await create(createData).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};

const handleError = (data) => {
  return {
    type: ActionTypes.userOperationClaim.HANDLE_ERROR_USER_OPERATION_CLAIM_ACTION,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.userOperationClaim.HANDLE_VALIDATION_USER_OPERATION_CLAIM_ACTION,
    payload: data,
  };
};

const userOperationClaimActions = {
  createAction,
};
export default userOperationClaimActions;
