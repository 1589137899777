import config from '../../../config.json';
import { formActions, reponseStatus, responseErrorStatus, storageKeys } from '../../../constants/Enum.jsx';
import {
  createWheelParticipation,
  getActiveWheel,
  reedemReward,
} from '../../../services/apiServices/wheelEventServices.js';
import ActionTypes from './actionTypes.js';
import Actions from './index.js';

const createWheelParticipationAction = (createData) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await createWheelParticipation(createData).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        await dispatch(createReducer());
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.wheelEvent.CreateWheelParticipation };
};

const reedemRewardAction = (key) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await reedemReward(key)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          const area = res?.data?.data.userArea;
          dispatch(Actions.userActions.selfUpdateAction({ userArea: area }, 'UserArea'));
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};

const getActiveWheelAction = () => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getActiveWheel();
        let response = res?.data?.data;
        await dispatch(getActiveWheelReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};

const getActiveWheelReducer = (data) => {
  return {
    type: ActionTypes.wheelEvent.GetActiveWheel,
    payload: data,
  };
};

const handleError = (data) => {
  return {
    type: ActionTypes.wheelEvent.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.wheelEvent.HandleValidation,
    payload: data,
  };
};
const cleanListReducer = () => {
  return { type: ActionTypes.wheelEvent.CleanList };
};
const handleRequestReducer = (data) => {
  return {
    type: ActionTypes.wheelEvent.HandleRequest,
    payload: data,
  };
};

const cleanState = (validation = false, ongoing) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(''));
      dispatch(cleanListReducer());
    }
  };
};

const wheelEventActions = {
  createWheelParticipationAction,
  reedemRewardAction,
  getActiveWheelAction,
  cleanListReducer,
  handleError,
  handleRequestReducer,
  handleValidationReducer,
  cleanState,
};
export default wheelEventActions;
